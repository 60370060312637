import React from 'react';

const cHariPendek 	= ["Min","Sen","Sel","Rab","Kam","Jum","Sab"];
let vTmpTgl		= [];
for(let i=1;i<=31;i++) { vTmpTgl.push(i)}
const cAngkaTgl = vTmpTgl;
const cBulanPendek = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"Mei",
	"Jun",
	"Jul",
	"Agu",
	"Sep",
	"Okt",
	"Nov",
	"Des"
];
const cBulanPanjang = [
	"Januari",
	"Februari",
	"Maret",
	"April",
	"Mei",
	"Juni",
	"Juli",
	"Agustus",
	"September",
	"Oktober",
	"November",
	"Desember"
];

const UFunc = {
handleErrors: function(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
},
formatAngka: function(_VARS){
	_VARS = parseFloat(_VARS) || 0;
	return _VARS.toLocaleString("ID", {maximumFractionDigits:2}); // "1,234.57"
},
isEmpty: function(val){
    return (val === undefined
        || typeof(val) === "undefined"
        || val === null
        || val === "NaN"
        //|| val === NaN
        //|| isNaN(val)===true
        || Object.keys(val).length === 0
        || val.length <= 0) ? true : false;
},
isNumeric: function(_STR) {
  if (typeof(_STR) !== "string") return false // we only process strings!  
  return !isNaN(_STR) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(_STR)) // ...and ensure strings of whitespace fail
},
nl2br: function(str) {
	if(UFunc.isEmpty(str)) return "";
    return str.replace(/\n/g,"<BR>");
},
br2nl: function(str) {
	if(UFunc.isEmpty(str)) return "";
	
    var HASIL    = str;
    HASIL        = HASIL.replace(/<br\s*\/?>/mg,"\n");
    HASIL        = HASIL.replace(/<BR\s*\/?>/mg,"\n");

    return HASIL;
},
removeTAGS: function(str) {
	if(UFunc.isEmpty(str)) return "";

	var div = document.createElement("div"); 
	div.innerHTML = str;

	return (div.textContent || div.innerText || "");
},
capitalize: function(str){

	return str.charAt(0).toUpperCase() + str.slice(1);
},
getObjectIndeks: function(_OBJECTDATA,_DATASRC,_NAMAOBJ){
	const vHasil = -1;
	_NAMAOBJ = (_NAMAOBJ || "id").trim();

	_DATASRC = (_DATASRC || "").toString().trim();
	if(_DATASRC === "") return vHasil;
	if((_OBJECTDATA||[]).length <= 0) return vHasil;

	return _OBJECTDATA.findIndex((vItems,vKey)=>{
		return (vItems[_NAMAOBJ]||"").toString().toUpperCase() === (_DATASRC||"").toString().toUpperCase();
	});

	/*for(var i=0;i<_OBJECTDATA.length;i++) {
		let vDataTks	= (_OBJECTDATA[i][_NAMAOBJ]) || "";
		if(vDataTks.toString() === _DATASRC.toString()) {
			return i;
			//vHasil = i; break;
		}
	}//-*/
},
getTabelRowIndeks: function(_TABELID,_TABELTR) {
	const vElTabel	= document.getElementById(_TABELID) || undefined;
	if(UFunc.isEmpty(vElTabel)) return -1;
	const vElTabelBody	= vElTabel.getElementsByTagName("tbody")[0] || undefined;
	if(UFunc.isEmpty(vElTabelBody)) return -1;

	const vELTabelRow = vElTabelBody.getElementsByTagName("tr") || undefined;
	//console.log("(UFunc) getTabelRowIndeks (vELTabelRow): "+vELTabelRow);
	if(UFunc.isEmpty(vELTabelRow)) return -1;

	for (var i=0; i<vELTabelRow.length; i++) {
		if((vELTabelRow[i].getAttribute("id")||"").toUpperCase() === (_TABELTR||"").toUpperCase()) {
			return i;
		}
	}
	return -1;
},
getOptionSelectTeks:function(_ELEMENT) {
	if(UFunc.isEmpty(_ELEMENT)) return "";

	return (_ELEMENT.options[_ELEMENT.selectedIndex].text);
},
filterRegex: function(_KEYWORD) {
	_KEYWORD		= _KEYWORD||"";
	var vInvalidchr = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;

	return _KEYWORD.replace(vInvalidchr,"");
},
renderHTML: function(escapedHTML: string) { 

	return React.createElement("span", { dangerouslySetInnerHTML: { __html: escapedHTML } });
},
Usercaptionfilter:function(_NAME,_JMLWORD) {
	_NAME 	= (_NAME||"Undf").trim();
	_JMLWORD = parseInt(_JMLWORD) || 2;

	const vTmpArr 	= _NAME.split(" ");
	let vUsercaption= "";
	for (var i = 0; i < vTmpArr.length; i++) {
		vUsercaption += vTmpArr[i];
		if(i >= _JMLWORD-1) break;
		vUsercaption += " "; 
	}

	return vUsercaption.trim();
},
leadZero: function(str, max) {
	str 	= str || "0";
    max 	= parseInt(max)||0;
    if(max<2)max=2;

    str = str.toString();
    var vDigits = max - (str.length);//-> 2 - 1 = 1
    for(var i=0;i <= vDigits - 1;i++)
	    str = "0" + str.toString();

    return str.toString();
},
toDate: function(_STRDATE) {
	if((_STRDATE||"")==="") {
		const vDefaultDT = new Date();

		_STRDATE = (vDefaultDT.getFullYear() + "-" +UFunc.leadZero(vDefaultDT.getMonth() + 1) + "-" + 
			UFunc.leadZero(vDefaultDT.getDate()));
	}

    var DatesCHK = _STRDATE;//---2011-04-21 OR 2011-04-21 00:00:00
    if(_STRDATE.length > 10 ) {
    	let vSplittwo	= _STRDATE.split(" ");
    	DatesCHK		= vSplittwo[0];
    }
    const [year, month, day] = [...DatesCHK.split('-')];
    return new Date(year, (month-1), day);//-->INDEX_MONTH_0_11;
},
toDatetime: function(_STRDATETIME) {
	//console.log("_STRDATETIME = "+_STRDATETIME);
	if((_STRDATETIME||"")==="") return new Date();

	var dt  = _STRDATETIME.split(/\-|\s/);
	return new Date(dt.slice(0,3).join('-') + ' ' + dt[3]);
},
isDateValid: function(_STRDATETIME) {
	if((_STRDATETIME||"")==="") return false;

	let DatesCHK = _STRDATETIME;//---2011-04-21 OR 2011-04-21 00:00:00
	let TimesCHK = "00:00:00";
	if(DatesCHK.length > 10 ) {
		let vSplittwo	= DatesCHK.split(" ");
		DatesCHK		= vSplittwo[0];
		TimesCHK		= vSplittwo[1];
	}
	const [vThn, vBln, vTgl] = [...DatesCHK.split('-')];
	const [vHrs, vMnt, vDtk] = [...TimesCHK.split(':')];

	const vDatecheck	= new Date(vThn,(vBln-1),vTgl,vHrs,vMnt,vDtk); 
	//console.log("[vThn, vBln, vTgl] = "+vThn+" - "+vBln+" - "+vTgl); 

	if (vDatecheck.getFullYear() == vThn 
		&& vDatecheck.getMonth() == (vBln-1) 
		&& vDatecheck.getDate() == vTgl) {
		return true;
    }
    return false;
},
DbDate: function(_DATE) {
	_DATE = _DATE || new Date();

	const vHasil = (_DATE.getFullYear() + "-" +UFunc.leadZero(_DATE.getMonth() + 1) + "-" + 
		UFunc.leadZero(_DATE.getDate()));
	return vHasil.toString();
},
DbDatetime: function(_DATETIME) {
	_DATETIME = _DATETIME || new Date();

	const vHasil = _DATETIME.getFullYear() + "-" +
		UFunc.leadZero(_DATETIME.getMonth() + 1) + "-" + 
		UFunc.leadZero(_DATETIME.getDate()) + " " +
		UFunc.leadZero(_DATETIME.getHours()) + ":" +
		UFunc.leadZero(_DATETIME.getMinutes()) + ":" +
		UFunc.leadZero(_DATETIME.getSeconds()) +
	"";
	return vHasil.toString();
},
SelisihHari: function(_TGLAWAL, _TGLAKHIR) {
    var vDateAwal   = UFunc.toDate(_TGLAWAL);
    var vDateAkhir  = UFunc.toDate(_TGLAKHIR);

    //return Math.round(Math.abs(vDateAkhir-vDateAwal)/(1000*60*60*24));
    return Math.round((vDateAkhir-vDateAwal)/(1000*60*60*24));
},
Datetimeselisih: function(_TGLAWALDt,_TGLAKHIRDt){
	const vSelisih = Math.abs(_TGLAKHIRDt - _TGLAWALDt);

	return { 
		day: Math.floor(vSelisih / (1000 * 60 * 60 * 24)),
		jam: Math.floor(vSelisih / (1000 * 60 * 60)),
		mnt: Math.floor(vSelisih / (1000 * 60)),
		dtk: Math.floor(vSelisih / 1000)
	};
},
DateIsBeyond: function(_TGLCEKDt) {
	var vTglToday 	= UFunc.DbDate(new Date());
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vTglToday);

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("vTglCek = "+vTglCek);

	if(vSelisih > 0) return true; else return false;
},
DateIsMundur: function(_TGLCEKDt){
	var vTglToday 	= UFunc.DbDate(new Date());
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vTglToday);

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	if(vSelisih < 0) return true; else return false;
},
DateIsExceededYear: function(_TGLCEKDt) {
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

	const vTglTodayDt= new Date();
	const vYearNow	= vTglTodayDt.getFullYear();

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vYearNow+"-01-01");

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("vSelisih = "+vSelisih);

	if(vSelisih < 0) return true; else return false;
},
TglAngka: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }
	//console.log("(TglAngka) _DATE = "+_DATE);
    return UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
HariAngka: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }

    return cHariPendek[ vTANGGAL.getDay() ]+","+ UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
TglAngkaDT: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+
    "";
},
WaktuAngka: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+" "+
    	UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
WaktuPendek: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return cHariPendek[ vTANGGAL.getDay() ]+", "+
    	UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+" "+
    	UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
JamMenit: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
	console.log("_DATETIME = "+_DATETIME);
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
JamMenitDetik: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+":"+
    	UFunc.leadZero(vTANGGAL.getSeconds())+
    "";
},
hdlKeyDownInput: function(_EV,_NEXTFIELD) {
	//console.log("(UFunc.hdlKeyDownInput) function = "+_EV.which);
	if(_EV.which===13 && (_EV.target.nodeName === "INPUT"
			|| _EV.target.nodeName === "SELECT"
			|| _EV.target.nodeName === "TEXTAREA")
	) {
		if (_EV.ctrlKey && _EV.target.nodeName === "TEXTAREA") {
			_EV.preventDefault();
			_EV.stopPropagation();
			var content = _EV.target.value;
			var start   = _EV.target.selectionStart;
			var substr1 = content.slice(0, start);
			var substr2 = content.slice(_EV.target.selectionEnd);
			//alert(_EV.target.selectionEnd);
			_EV.target.value  = substr1 + '\n' + substr2;
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();
		const ElNext	= document.getElementById(_NEXTFIELD);
		//if(!UFunc.isEmpty(ElNext))
		ElNext && ElNext.focus();
	}
},
getRandomColor: function() {
	const letters = '0123456789ABCDEF'.split('');
	let color = '#';
	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	} 
	return color;
},
getBase64Image: function(img) {
	var canvas 		= document.createElement("canvas");


	canvas.width 	= img.width;
	canvas.height 	= img.height;

	var ctx 		= canvas.getContext("2d");
	ctx.drawImage(img, 0, 0, 1000, 700);
	var dataURL 	= canvas.toDataURL("image/png");
	return dataURL.replace(/^data:image\/(png|jpeg|gif);base64,/, "");
},
getBase64: function(file) {
	return new Promise((resolve,reject) => {
		let fileInfo;
		// Make new FileReader
		let reader = new FileReader();
		// Convert the file to base64 text
		reader.readAsDataURL(file);

		// on reader load something...
		reader.onload = () => {
			// Make a fileInfo Object
			const baseURL = reader.result;
			resolve(baseURL);
		};
		reader.onError = (error)=>{
			console.log(error);
			reject(error);
		}
    });
},
Rendernota: function(_DATANOTAOBJ) {
	const vDatanotaObj = _DATANOTAOBJ || {};

	//->{"tgl_bayar":"2022-11-11 23:10:46","status_bayar":"KASIR","nilai_uang":"500000","nilai_bayar":"400000","nota":"20221111004","tgl_periksa":"2022-11-11","nama_dokter":"AGNES ENDRA, SpPros, DRG","pasien_id":"40927","pasien_caption":"NADYNE LINTANG A (PREMIUM)","nilai_bruto":"409120","diskon":"9120","note_dokter":"LEPAS JAHITAN","note_nota":"Catatan Tambahan Dad Asdasd","metode_payment":"DEBET BANK LAIN","id_payment":"12","ket_payment":"Dasd Aadasdad","kasir":"ADE","id_rekammedis":"228206","janjian_arr":[{"jam_awal":"10:00:00","tgl_antri":"2022-11-13","id":"49"},{"jam_awal":"10:00:00","tgl_antri":"2022-11-15","id":"50"}],"items_arr":[{"status_ada_garansi":"YA","tgl_batas_garansi":null,"tarif_terapi":"138680","caption_terapi":"APLIKASI METRONIDASOL GEL SEDANG","id_terapi_sub":"1787","id_item":"99393091"},{"status_ada_garansi":"YA","tgl_batas_garansi":null,"tarif_terapi":"170600","caption_terapi":"APLIKASI METRONIDASOL GEL BANYAK","id_terapi_sub":"1786","id_item":"99393092"},{"status_ada_garansi":"TIDAK","tgl_batas_garansi":null,"tarif_terapi":"99840","caption_terapi":"ALVEOLECTOMY SEDANG","id_terapi_sub":"733","id_item":"99393093"}]}

	let vHTMLs	= "";

	const vDataItemArr	= (vDatanotaObj.items_arr || []);
	const vStatusbayar	= vDatanotaObj.status_bayar || "KASIR";
	const vNilaibruto	= parseFloat(vDatanotaObj.nilai_bruto) || 0;
	const vNilaidiskon	= parseFloat(vDatanotaObj.diskon) || 0;
	const vNilaiasuransi= parseFloat(vDatanotaObj.nilai_asuransi) || 0;
	const vNilainetto	= vNilaibruto - vNilaidiskon - vNilaiasuransi;
	const vBayarbefore	= parseFloat(vDatanotaObj.bayar_before) || 0;
	const vNilaiuang	= parseFloat(vDatanotaObj.nilai_uang) || 0;
	const vNilaitagihan	= vNilainetto - vBayarbefore;
	const vNilaisisa	= vNilaitagihan - vNilaiuang;

	vHTMLs += `
		<table id="idtabelhtml" align="left">
		<tbody>
		_TRBODYLOGO_
	`;
	vHTMLs += `
		<tr valign="top">
			<td colSpan="4" align="center" width="50%"><b>_NAMAPERUSAHAAN_</b></td>
		</tr>
		<tr valign="top">
			<td colSpan="4" align="center">_ALAMATPERUSAHAAN_</td>
		</tr>
		_TRBODYTELP_
	`;
	vHTMLs += `
		<tr valign="top">
			<td colSpan="4"><hr/></td>
		</tr>
		<tr valign="top">
			<td colSpan="2">Tanggal</td>
			<td colSpan="2">`+UFunc.WaktuPendek(vDatanotaObj.tgl_bayar)+`</td>
		</tr>
	`;
	if(vStatusbayar!=="KASIR")
		vHTMLs += `
		<tr valign="top">
			<td colSpan="2">Tgl Periksa</td>
			<td colSpan="2">`+UFunc.TglAngka(vDatanotaObj.tgl_periksa)+`</td>
		</tr>
		`;
	vHTMLs += `
		<tr valign="top">
			<td colSpan="2">No.Nota</td>
			<td colSpan="2">`+(vDatanotaObj.nota||"-UNDF-")+`</td>
		</tr>
		<tr>
			<td colSpan="2">PASIEN</td>
			<td colSpan="2"><b>`+(vDatanotaObj.pasien_caption||"").toUpperCase()+`</b></td>
		</tr>
		`;
	if((parseInt(vDatanotaObj.id_asuransi||"0") > 0)) {
		vHTMLs += `
		<tr>
			<td colSpan="2">ASURANSI</td>
			<td colSpan="2"><b>`+(vDatanotaObj.nama_asuransi||"").toUpperCase()+`</b></td>
		</tr>
			`;
	}
	vHTMLs += `
		<tr>
			<td colSpan="4">&nbsp;</td>
		</tr>
		<tr>
			<td colSpan="2">Dokter</td>
			<td colSpan="2">`+(vDatanotaObj.nama_dokter||"UNDF, DRG")+`</td>
		</tr>
		<tr valign="top">
			<td colSpan="2">Kasir</td>
			<td colSpan="2">`+(vDatanotaObj.kasir||"-")+`</td>
		</tr>
		<tr>
			<td colSpan="4"><hr/></td>
		</tr>
		</tbody>
		</table>
		`;
	vHTMLs += `
		<table id="idtabelhtml" align="left">
		<tbody>
		`;
	vDataItemArr.map((vItems,vKey)=>{
		const {
			id,caption_terapi,tarif_terapi,qty,diagnosa
		} = vItems;

		vHTMLs += `
		<tr valign="top" key="`+vKey+`">
			<td align="right">`+(vKey+1)+`.</td>
			<td colspan="2">`+
				`<div>`+(caption_terapi||"UNDF Terapi")+`</div>`+
				(parseFloat(tarif_terapi||0) > 0 
					? `<div>`+(UFunc.formatAngka(qty))+` x `+(UFunc.formatAngka(tarif_terapi))+`</div>`
					: ``)+
				((diagnosa||"") !== "" 
					? `<div><i>`+(diagnosa||"")+`</i></div>`
					: ``)+
			`</td>
			<td align="right">`+UFunc.formatAngka(parseFloat(tarif_terapi||0) * parseInt(qty||1))+`</td>
		</tr>
		`;
	});
	vHTMLs += `
		<tr>
			<td colspan="4"><hr/></td>
		</tr>
		<tr>
			<td align="right" colspan="3"><b>Jumlah &middot; (Rp)</b></td>
			<td align="right"><b>`+UFunc.formatAngka(vNilaibruto)+`</b></td>
		</tr>
		<tr>
			<td align="right" colspan="3">Diskon &middot; (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vNilaidiskon)+`</td>
		</tr>
		`;
	if((parseInt(vDatanotaObj.id_asuransi||"0") > 0)) {
		vHTMLs += `
		<tr>
			<td align="right" colspan="3"><b>Klaim Asuransi &middot; (Rp)</b></td>
			<td align="right"><b>`+UFunc.formatAngka(vNilaiasuransi)+`</b></td>
		</tr>
		`;
	}
	if(vStatusbayar!=="KASIR")
		vHTMLs += `
		<tr>
			<td colspan="3">&nbsp;</td>
			<td><hr/></td>
		</tr>
		<tr>
			<td align="right" colspan="3">N.Transaksi &middot; (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vNilainetto)+`</td>
		</tr>
		<tr>
			<td align="right" colspan="3">Terbayar &middot; (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vBayarbefore)+`</td>
		</tr>
		`;
	vHTMLs += `
		<tr>
			<td colspan="3">&nbsp;</td>
			<td><hr/></td>
		</tr>
		<tr>
			<td align="right" colspan="3">N.Tagihan &middot; (Rp)</td>
			<td align="right">`+UFunc.formatAngka(vNilaitagihan)+`</td>
		</tr>
		<tr>
			<td align="right" colspan="3"><b>JML BAYAR &middot; (Rp)</b></td>
			<td align="right"><big><b>`+UFunc.formatAngka(vNilaiuang)+`</b></big></td>
		</tr>
		<tr>
			<td colspan="3">&nbsp;</td>
			<td><hr/></td>
		</tr>
		`;
	if(vNilaisisa > 0)
		vHTMLs += `
		<tr>
			<td align="right" colspan="3"><b>SISA CICILAN &middot; (Rp)</b></td>
			<td align="right"><b>`+UFunc.formatAngka(vNilaisisa)+`</b></td>
		</tr>
		`;
	else
		vHTMLs += `
		<tr>
			<td align="right" colspan="3">Kembalian &middot; (Rp)</td>
			<td align="right">`+UFunc.formatAngka(Math.abs(vNilaisisa))+`</td>
		</tr>
		`;

	if(parseInt(vDatanotaObj.id_payment||0) > 0) {
		vHTMLs += `
		<tr>
			<td colspan="4"><hr/></td>
		</tr>
		<tr>
			<td align="left" colspan="2">Metode Payment</td>
			<td align="right" colspan="2"><b>`+vDatanotaObj.metode_payment+`</b></td>
		</tr>
		`;
		if((vDatanotaObj.ket_payment||"").trim()!=="")
			vHTMLs += `
			<tr>
			<td align="right" colspan="1">&nbsp;</td>
			<td align="right" colspan="3"><i>`+vDatanotaObj.ket_payment+`</i></td>
			</tr>
			`;

	}

	//console.log("vStatusbayar = "+vStatusbayar);
	if(vStatusbayar==="KASIR") {
		const vJanjianArr	= vDatanotaObj.janjian_arr || [];
		//console.log("vJanjianArr = "+JSON.stringify(vJanjianArr));

		if ((vDatanotaObj.note_nota||"").trim()!=="" 
			|| (vDatanotaObj.note_dokter||"").trim()!==""
			|| vJanjianArr.length > 0
		) {
		vHTMLs += `
		<tr>
			<td colspan="4"><hr/></td>
		</tr>
		<tr>
			<td align="left" colspan="4"><b>Catatan</b></td>
		</tr>
		<tr>
			<td>&nbsp;</td>
			<td align="left" colspan="3">`+
				((vDatanotaObj.note_dokter||"").trim()!==""?vDatanotaObj.note_dokter:"")+
				((vDatanotaObj.note_nota||"").trim()!=="" && (vDatanotaObj.note_dokter||"").trim()!=="" ? " &middot; " : "")+
				((vDatanotaObj.note_nota||"").trim()!==""?vDatanotaObj.note_nota:"")+
			`</td>
		</tr>
		`;
		if(vJanjianArr.length > 0)
			vHTMLs += `
			<tr>
				<td>&nbsp;</td>
				<td align="left" colspan="3">
				<ol>
			`+
			(vJanjianArr.map((vItems,vKeys)=>{
				const {
					id,tgl_antri,jam_awal,no_urut,nama_dokter,cabang_caption
				} = vItems;

				return `<li>
				Tgl <b>`+UFunc.HariAngka(tgl_antri)+`</b><span> &middot; </span>
				Jam <b>`+
				(parseInt(no_urut||0) > 0 ? "Urutan No-"+no_urut : (jam_awal||"").substring(0,5) )+
				`</b>
				<div>(`+cabang_caption+`)</div>
				</li>`;
			})).join("")+
				`
				</ol>
				</td>
			</tr>
			`;
		} 
	}

	vHTMLs += `
		_TRFOOTERNOTA_
		<tr>
			<td width="5%" style="min-width:7mm;">&nbsp;</td>
			<td width="40%">&nbsp;</td>
			<td>&nbsp;</td>
			<td width="30%">&nbsp;</td>
		</tr>
		</tbody>
		</table>
		`;
	//-*/
	return vHTMLs;
},
}
export {UFunc,cBulanPanjang,cHariPendek,cAngkaTgl,cBulanPendek};