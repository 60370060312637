import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { CNavGroup, CNavItem, CNavTitle, CImage } from '@coreui/react'
import PropTypes from 'prop-types'

export const ContSidebarNav = ({ items }) => {
	const location = useLocation()
	const uNavigate = useNavigate()

	const uMenufilterArr = items.filter(vItems => vItems.posisi==="SIDEBAR");

	let uMenusidebarArr = []
	uMenufilterArr.map((vItems,vKeys)=>{
		let vKomponen;

		const vChildrenArr	= vItems.items || [];
		let vMenuchildarr	= [];
		vChildrenArr.map((vItemsch,vKeysch)=>{
			if(vItemsch.komponen === "CNavGroup") vKomponen = CNavGroup;
			else if(vItemsch.komponen === "CNavTitle") vKomponen = CNavTitle;
			else vKomponen = CNavItem;

			const vTmpobj	= {
				component: vKomponen,
				to: vItemsch.to,
				src: vItemsch.src,
				name: vItemsch.name,
				caption: vItemsch.caption,
			}
			vMenuchildarr.push(vTmpobj);
		})

		if(vItems.komponen === "CNavGroup") vKomponen = CNavGroup;
		else if(vItems.komponen === "CNavTitle") vKomponen = CNavTitle;
		else vKomponen = CNavItem;

		const vMenuobj	= {
			component: vKomponen,
			to: vItems.to,
			src: vItems.src,
			name: vItems.name,
			caption: vItems.caption,
			items: vMenuchildarr
		}
		if(vMenuchildarr.length <= 0) vMenuobj.items = undefined;
		uMenusidebarArr.push(vMenuobj);
	})

	//console.log("(ContSidebarNav) uMenusidebarArr => "+JSON.stringify(uMenusidebarArr));

	const navLink = (caption, src) => {
		return (
		<>
		{src && (<CImage src={src} className="me-4" height={25}/>)}
		{caption && caption}
		</>
		)
	}

	const navItem = (item, index) => {
		const { component, caption, src, ...rest } = item
		const Component = component
		return (
			<Component {...(rest.to && !rest.items && {
				component: NavLink, })}
				key={index}
				{...rest}>
			{navLink(caption, src)}
			</Component>
		)
	}

	const navGroup = (item, index) => {
		const { component, caption, src, to, ...rest } = item
		const Component = component

		return (
			<Component idx={String(index)} key={index}
				toggler={navLink(caption, src)}
				visible={location.pathname.startsWith(to)}
				{...rest}>
			{item.items?.map((item, index) =>
				item.items ? navGroup(item, index) : navItem(item, index),
				)}
			</Component>
		)
	}

	return (
		<React.Fragment>
		{uMenusidebarArr &&
			uMenusidebarArr.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
		</React.Fragment>
	)
}

ContSidebarNav.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
}